// Storing these in a separate file because they should be available to the runtime, instead of just TypeScript.
// I haven't figured out how to auto-import this. Needs a bit more research.
export enum VacancyStatus {
  Draft = 'draft',
  Published = 'published',
  Archived = 'archived',
  Deleted = 'deleted',

  // TODO: see https://app.clickup.com/t/86c24x5eg
  // In doubt - needs a bit more work:
  Filled = 'filled',
  Expired = 'expired',
}

export enum VacancyType {
  Volunteering = 'volunteering',
  P2p = 'p2p',
  Activity = 'activity',
  // Only used by meedoen (for language courses)
  Workshop = 'workshop', // TODO: remove.
}

export enum Frequency {
  Once = 'once', // Can also be multiple days for vacancies
  MultipleDays = 'multi_day', // Only used for activities
  Flexible = 'flexible', // Not used for activities
  Regularly = 'regularly', // Used for activities + other types
}

export enum TimeOfDay {
  Morning = 'morning',
  Afternoon = 'afternoon',
  Evening = 'evening',
}

export enum LocationOption {
  Address = 'address',
  Flexible = 'flexible_location',
  Home = 'from_home',
}
